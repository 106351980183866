.expandable-container {
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 10px;
}

.expandable-content {
  display: inline-block;
  width:100%;
  vertical-align: top;
  
  overflow: hidden;
  height: auto;
  max-height: 100%; /* Set an initial max-height for the collapsed state */
  transition: height 0.5s ease; /* Smooth transition */
}



.expand-toggle-label.less {
  color: red;
}

.expand-toggle-label {
  color: blue;
  cursor: pointer;
  font-weight: normal;
  
  display: inline;
}
