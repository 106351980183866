.drag-container {
    display: flex;
    gap: 10px;
}

.drag-item {
    width: 100px;
    height: 100px;
    background-color: lightblue;
    text-align: center;
    line-height: 100px;
    cursor: pointer;
}

.react-datepicker-wrapper {
    display: flex;
}

.react-datepicker-wrapper .react-datepicker__input-container {
    display: flex;
    width: 100%;
}

.react-datepicker__input-container input {
    height: 40px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
}

.react-datepicker__header {
    background: white;
}

.p-dropdown-items-wrapper {
    height: auto;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: #ffffff;
    border-color: #315975;
    color: #315975;
    padding: 5px;
}

.p-menuitem-text {
    font-size: 13px;
}

.p-ink {
    background-color: unset;
}

.user-data-table-height .p-datatable-wrapper {
    height: 370px;
}

.auto-data-table-height .p-datatable-wrapper {
    height: auto;
    max-height: 250px;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    box-shadow: none;
}

.br-5 {
    border-radius: 5px;
}

.br-1 {
    border-radius: 10px;
}

.br-2 {
    border-radius: 20px;
}

.layout-topbar .custom-layout-topbar-menu {
    margin-left: 0;
    position: absolute;
    flex-direction: column;
    background-color: var(--surface-overlay);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding: 1rem;
    right: 2rem;
    top: 5rem;
    min-width: 15rem;
    display: none;
    animation: scalein 0.15s linear;
}

.d-none {
    display: none !important;
}

.layout-topbar .layout-topbar-button span {
    font-size: 1rem;
    display: block;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 0;
    justify-content: center;
    display: flex;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #315975;
    background: #315975;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #31597510;
    border-color: #315975;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #315975;
    background: #315975;
    color: #ffffff;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #315975;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #315975;
    background: #315975;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #315975;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: #315975;
    background: #315975;
    color: #ffffff;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #31597510;
    border-color: #315975;
}

.p-dropdown:not(.p-disabled):hover {
    border-color: #315975;
}

.p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #31597510;
    border-color: #315975;
}

.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #31597510;
    border-color: #315975;
}

.p-inputtext:enabled:hover {
    border-color: #315975;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.p-button:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #31597510, 0 1px 2px 0 black;
}

.p-button.p-button-text:enabled:hover,
.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: #31597510;
    color: #315975;
    border-color: transparent;
}

.p-button.p-button-outlined:enabled:hover,
.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: #31597510;
    color: #315975;
    border: 1px solid;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #315975;
    background: #31597510;
}

.padding-8 {
    padding: 8px;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #315975;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #315975;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: #315975;
}

.h-500 .p-datatable-wrapper {
    height: 500px;
}

.p-button:disabled {
    background: #80808050 !important;
    border: 1px solid #80808080 !important;
    color: #808080 !important
}

.inactive-btn {
    width: 200px;
    background: lightgray !important;
    border: 0px;

}

.active-btn {
    width: 200px;
    border: 0px;

}

.dis-option-hover:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    background: none;
    cursor: default;
}

.dis-option-hover .p-listbox-list .p-listbox-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffffff;
    border-color: #12344b;
    color: #12344b;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    z-index: 1;
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: #12344b;
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.p-checkbox-checked.p-checkbox-disabled .p-checkbox-box {
    border: gray !important;
    background: gray !important;
}

.text-underline {
    text-decoration: underline;
}

td.col-span-1::before {
    content: attr(colSpan);
    display: none;
    /* Hide the generated content */
}

.text-three-dot {

    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    white-space: nowrap;

}

.clr-white {
    color: white
}

.form-group .form-label-clr-white {
    color: white !important
}

.tag-tooltip .p-tooltip-text {
    background: white;
}

.text-area .ql-toolbar .ql-formats .ql-header {
    display: none;
}

.text-area .ql-toolbar .ql-formats .ql-font {
    display: none !important;
}

.text-area .ql-toolbar .ql-formats .ql-link {
    display: block !important;
}

.text-area .ql-toolbar .ql-formats .ql-image {
    display: none !important;
}

.p-progressbar .p-progressbar-label {
    color: white;
    font-weight: 500;
    line-height: 1.5rem;
}

.p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: #315975;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
}

.form-upload-btn .p-button.p-component.p-fileupload-choose {
    width: 120px;
}

.form-import-btn .p-button.p-component.p-fileupload-choose {
    width: 120px;
}

textarea {
    resize: none;
}

.accfull a .p-accordion-header-text {
    width: 100%;
}

.roundedge ul li.p-highlight {
    border: 2px solid #315975;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;

}

.p-datatable .p-datatable-loading-icon {
    width: 3rem;
    height: 3rem;
}

.p-accordion-header a {
    text-decoration: none;
    color: black;
}