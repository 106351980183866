.login-container {
    color: rgb(233, 223, 223);
    background-color: rgba(255, 255, 255, 0.3);
    padding-top: 3%;
    padding-bottom: 3%;
    padding-right: 6%;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    width: 1110px;
    height: 476px;
    align-items: center;
    backdrop-filter: blur(10px);
  }

  .image-container {
    width: 60%;
    height: 40%;
    overflow: hidden;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 5%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  
  .login-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .login-header {
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 15%;
  }

  .form-container {
    width: 30%;
  }
  
  .form-group {
    margin-top: 8%;
  }
  
  .form-label {
    font-weight: 400;
    font-size: 14px;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
  }
  
  .sign-in-button {
    font-weight: 700;
    font-size: 14px;
    padding: 10px 20px;
    color: #fcf8f8;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin-top: 15%;
    background-color: #41AA95
  }
  
  .other-options {
    display: flex;
    justify-content: left;
    margin-top: 5%;
  }
  
  .forgot-password {
    font-size: 14px;
    font-weight: 900;
    margin-top: 2%;
  }

  .no-account {
    font-size: 14px;
    font-weight: 400;
  }
  
  .sign-up {
    font-size: 14px;
    font-weight: 900;
    cursor: pointer;
    padding-left: 2%;
  }

  @media (max-width: 768px) {
    .login-container {
      flex-direction: column; /* Stack elements vertically */
      width: 90%; /* Adjust width for smaller screens */
      height: auto; /* Let the height adjust as per content */
      padding: 5% 2%; /* Adjust padding */
    }

    .login-image {
      width: 100%;
      height: 95%;
      object-fit: cover;
    }


    .form-container {
      margin-top: 10%;
      width: 70%;
    }
  }
  